import React, { useEffect, useContext, useState, useRef } from "react"
import styled from "styled-components"
import { useTranslation, Trans } from "react-i18next"
import { navigate } from "gatsby"
import { useForm, Controller } from "react-hook-form"
import { subscribeToNewsletter } from "../context/utils/klaviyoEvents"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Box from "./Box"
import Link from "../Link"
import Checkbox from "../ui/Checkbox"
import Button from "../ui/Button"
import Address from "./Address"
import Summary from "./Summary"
import "@adyen/adyen-web/dist/adyen.css"
import Dinero from "dinero.js"
import { identify } from "../context/utils/klaviyoEvents"

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const Checkout = styled.div`
  padding: 2rem 2rem 0;
  margin: 0 0 2rem;
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0;
    padding: 0 3rem 3rem;
  }

  p {
    color: ${(p) => p.theme.colors.darkGrey};
    margin: 2rem 0 0;
    letter-spacing: 0;
  }

  form > div {
    padding: 2rem 2rem 0 0;

    ${MEDIA_MIN_MEDIUM} {
      padding: 3rem 0 0;
    }
  }
`

const Label = styled.label`
  margin-left: 1rem;
  margin-top: 0.2rem;
  text-transform: initial;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0;
  white-space: pre-wrap;
`

const AddressBox = styled(Box)`
  margin: 0 0 2rem;
  padding: 0 2rem 0;
  border: none;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2rem;
    padding: 0 3rem 0;
  }
`

const SummaryBox = styled(Box)`
  margin: 0 0 2rem;
  padding-bottom: 1rem;
  border: none;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2rem;
  }

  h3 {
    margin-left: 0;
  }
`

const ContinueButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

const DropinContainer = styled.div`
  .adyen-checkout__threeds2__challenge,
  .adyen-checkout__iframe {
    width: 100%;
  }

  .adyen-checkout__iframe {
    margin: 0 2rem;
  }
`

const RetryButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

export default ({ isShippingValid }) => {
  const { t } = useTranslation("translations")
  const {
    ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY,
    getPaymentMethods,
    cartToOrder,
    makePayment,
    cart,
    shippingAddress,
    setShippingAddress,
    currentStore,
    languageCode,
    setOrder,
    closeCart,
    setIsLoading,
    makeDetailsCall,
    setIsCartOpen
  } = useContext(BrinkContext)
  const [displayError, setDisplayError] = useState(false)
  const [newsletterChecked, setNewsletterChecked] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [showCheckout, setShowCheckout] = useState(true)
  const [showAdyen, setShowAdyen] = useState(false)
  const [showPayPalButton, setShowPayPalButton] = useState(false)
  const [showRetryButton, setShowRetryButton] = useState(false)
  const [payPalActions, setPayPalActions] = useState(null)
  const {
    control,
    handleSubmit,
    errors,
    triggerValidation,
    formState: { isValid },
    setValue
  } = useForm({ mode: "onBlur" })

  const handleResult = (response, order) => {
    if (response.resultCode === "Authorised") {
      const physicalOrderLines = order.orderLines.filter((ol) => ol.type === "physical")
      typeof window.gtag !== "undefined" &&
        window.gtag("event", "purchase", {
          transaction_id: order.id,
          value: Dinero({
            amount: order.orderAmountWithDiscount,
            currency: order.currencyUnit
          }).toUnit(),
          currency: order.currencyUnit,
          tax: Dinero({
            amount: order.orderTaxAmount,
            currency: order.currencyUnit
          }),
          shipping: Dinero({
            amount: order.orderLines
              .filter((ol) => ol.type === "shipment")
              .reduce((prev, curr) => {
                return prev + curr.totalAmount
              }, 0),
            currency: order.currencyUnit
          }).toUnit(),
          items: physicalOrderLines.map((ol) => ({
            id: ol.productId,
            name: ol.name,
            brand: "BrinkCommerce",
            quantity: ol.quantity,
            price: Dinero({
              value: ol.totalAmountWithDiscount,
              currency: order.currencyUnit
            }).toUnit()
          }))
        })
      setOrder(order)
      closeCart()
      setIsCartOpen(false)
      newsletterChecked && subscribeToNewsletter(currentStore.countryCode, shippingAddress.email)
      navigate("/success/", {
        state: {
          order,
          cart,
          billingAddress: shippingAddress,
          resultCode: response.resultCode
        }
      })
    } else {
      console.log(response)
      setShowCheckout(false)
      setDisplayError(true)
      setShowRetryButton(true)
    }
  }

  const dropin = useRef(null)
  const paypalComponent = useRef(null)
  useEffect(() => {
    if (!paypalComponent.current) return
    if (!showPayPalButton) return

    if (paypalComponent.current._node) {
      paypalComponent.current.remount()
    } else {
      paypalComponent.current.mount("#paypal-container")
    }
  }, [showPayPalButton])

  useEffect(() => {
    if (!payPalActions) return
    if (isValid) {
      payPalActions.enable()
    } else {
      payPalActions.disable()
    }
  }, [payPalActions, isValid])

  useEffect(() => {
    showRetryButton && dropin.current.unmount()
  }, [showRetryButton])

  useEffect(() => {
    if (!showAdyen || !shippingAddress || !isShippingValid) return

    identify(shippingAddress)
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "checkout_progress", {
        checkout_step: 4,
        checkout_option: "Started Payment"
      })
    const generateAdyenPayment = async () => {
      setIsLoading(true)
      const [paymentMethods, order] = await Promise.all([getPaymentMethods(), cartToOrder()])

      const onSubmit = (state, adyen) => {
        if (state.data.paymentMethod.type === "applepay" && !state.data.paymentMethod.applePayToken) {
          return
        }
        typeof window.gtag !== "undefined" &&
          window.gtag("event", "checkout_progress", {
            checkout_step: 5,
            checkout_option: "Payment Submited"
          })
        setDisplayError(false)
        setIsLoading(true)
        makePayment(order, state.data.paymentMethod, state.data.storePaymentMethod, state.data.browserInfo)
          .then((response) => {
            const { paymentResult, order } = response
            if (paymentResult.action) {
              adyen.handleAction(paymentResult.action)
              setOrder(order)
              setIsLoading(false)
              setShowCheckout(false)
            } else {
              handleResult(paymentResult, order)
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            setIsLoading(false)
            navigate("/error")
          })
      }
      const onAdditionalDetails = (state, adyen) => {
        makeDetailsCall(order.id, state.data.details)
          .then((response) => {
            const { paymentResult, order } = response
            if (paymentResult.action) {
              adyen.handleAction(paymentResult.action)
              setOrder(order)
              setIsLoading(false)
              setShowCheckout(false)
            } else {
              handleResult(paymentResult, order)
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            setIsLoading(false)
            navigate("/error")
          })
      }

      const configuration = {
        amount: {
          value: cart.totalPriceWithDiscount,
          currency: currentStore.currencyUnit
        },
        paymentMethodsResponse: paymentMethods,
        clientKey: ADYEN_CLIENT_KEY,
        locale: languageCode,
        environment: ADYEN_ENVIRONMENT,
        showPayButton: false,
        onSubmit,
        onAdditionalDetails,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            name: t("Credit or debit card")
          }
        },
        applepay: {
          amount: {
            value: cart.totalPriceWithDiscount,
            currency: currentStore.currencyUnit
          },
          countryCode: currentStore.countryCode,
          onSubmit
        }
      }

      import("@adyen/adyen-web")
        .then(({ default: AdyenCheckout }) => AdyenCheckout)
        .then((AdyenCheckout) => AdyenCheckout(configuration))
        .then((checkout) => {
          if (dropin.current) {
            dropin.current.unmount()
          }
          dropin.current = checkout
            .create("dropin", {
              onSelect: ({ props: { type } }) => setShowPayPalButton(type === "paypal")
            })
            .mount("#dropin-container")

          if (paymentMethods.paymentMethods.find((p) => p.type === "paypal")) {
            paypalComponent.current = checkout.create("paypal", {
              showPayButton: true,
              onInit: (_, actions) => {
                actions.disable()
                setPayPalActions(actions)
              },
              onClick: () => triggerValidation(),
              onCancel: () => setShowCheckout(true),
              onError: () => setShowCheckout(true)
            })
          }
        })
        .catch((error) => console.error(error))
    }

    generateAdyenPayment().finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAdyen, shippingAddress, cart.cartItems, isShippingValid])

  const onContinueToAdyen = (data) => {
    setShippingAddress(data)
    setShowSummary(true)
    setShowAdyen(true)
  }

  return (
    <>
      {!showAdyen && (
        <form onSubmit={handleSubmit(onContinueToAdyen)}>
          <AddressBox>
            <Address control={control} errors={errors} showAdyen={showAdyen} setValue={setValue} />
          </AddressBox>
          <ContinueButton type="submit">{t("Continue")}</ContinueButton>
        </form>
      )}
      {showAdyen && showSummary && (
        <SummaryBox>
          <Summary
            setShowSummary={(val) => {
              setShowAdyen(false)
              setShowSummary(val)
            }}
          />
        </SummaryBox>
      )}
      {showAdyen && (
        <>
          {displayError && (
            <>
              <ErrorMessage>
                {t("Something went wrong with your payment. Please try another option or contact our support.")}
              </ErrorMessage>
            </>
          )}
          {showRetryButton && (
            <>
              <RetryButton
                onClick={() => {
                  setDisplayError(false)
                  setShowCheckout(true)
                  setShowRetryButton(false)
                  dropin.current.update()
                }}
              >
                Retry
              </RetryButton>
            </>
          )}
          {!showRetryButton && <p>{t("All payments are encrypted and secure")}</p>}
          <DropinContainer id="dropin-container" />
          <Checkout>
            <form onSubmit={handleSubmit(() => dropin.current.submit())}>
              {showCheckout && (
                <>
                  <Controller
                    as={Checkbox}
                    control={control}
                    type="checkbox"
                    name="newsletter"
                    value="newsletter"
                    defaultValue={false}
                    onChange={() => setNewsletterChecked(!newsletterChecked)}
                  >
                    <Label>{t("Subscribe to newsletter")}</Label>
                  </Controller>
                  <Controller
                    as={Checkbox}
                    control={control}
                    rules={{
                      required: t("You need to accept the terms and agreements to be able to place an order")
                    }}
                    type="checkbox"
                    name="agreement"
                    value="agreement"
                    errors={errors}
                    defaultValue={false}
                  >
                    <Label>
                      <Trans i18nKey="termsAgreement">
                        I agree to the
                        <Link underline color="black" to="/terms-and-conditions/">
                          terms and conditions
                        </Link>
                        of Brink Commerce AB
                      </Trans>
                    </Label>
                  </Controller>

                  {!showPayPalButton && <ContinueButton type="submit">{t("Complete order")}</ContinueButton>}
                </>
              )}
              <div id="paypal-container" style={{ display: showPayPalButton ? "block" : "none" }} />
            </form>
          </Checkout>
        </>
      )}
    </>
  )
}
