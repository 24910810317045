import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  height: 5rem;
  align-items: center;
`

const Radio = styled.input.attrs((p) => ({
  type: "radio",
  name: p.name,
  id: p.id,
  value: p.value
}))`
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  padding: 0;
  border: 0.1rem solid ${(p) => p.theme.colors.secondary};
  border-radius: 50%;
  position: relative;

  &:checked::after {
    content: "";
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.secondary};
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }

  &:focus {
    outline: none;
  }
`

export default ({ children, id, name, preSelected, ...other }) => (
  <Container {...other}>
    <Radio id={id} name={name} defaultChecked={preSelected} />
    {children}
  </Container>
)
