import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import { CountryRegionData } from "react-country-region-selector"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import Link from "../Link"

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  padding: 1.7rem 0 1.5rem;

  > div {
    display: flex;
    line-height: 1.6rem;
  }

  img {
    height: 2rem;
    margin-right: 1rem;
    margin-top: -0.3rem;
  }
`

const ShippingRow = styled(Row)`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
`

const ChangeLink = styled(Link)`
  color: ${(p) => p.theme.colors.secondary};
  height: 1.4rem;
  flex-shrink: 0;
  margin-left: 1rem;
`

const Label = styled.span`
  min-width: 7.5rem;
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    min-width: 10rem;
  }
`
const getFullCountryName = (countryCode) => CountryRegionData.find((country) => country[1] === countryCode)

export default ({ setShowSummary }) => {
  const { t } = useTranslation("translations")
  const { shippingAddress } = useContext(BrinkContext)
  const { colors } = useContext(ThemeContext)

  return (
    <>
      <ShippingRow>
        <div>
          <Label>{t("E-mail")}:</Label> {shippingAddress.email}
        </div>
        <ChangeLink
          to="/"
          underline
          color={colors.secondary}
          onClick={(e) => {
            e.preventDefault()
            setShowSummary(false)
          }}
        >
          {t("Change")}
        </ChangeLink>
      </ShippingRow>
      <ShippingRow>
        <div>
          <Label>{t("Address")}:</Label> {shippingAddress.address}, {shippingAddress.postalCode} {shippingAddress.city},{" "}
          {getFullCountryName(shippingAddress.country)[0]}
        </div>
        <ChangeLink
          to="/"
          underline
          color={colors.secondary}
          onClick={(e) => {
            e.preventDefault()
            setShowSummary(false)
          }}
        >
          {t("Change")}
        </ChangeLink>
      </ShippingRow>
    </>
  )
}
