import React, { useEffect, useContext, useState, useRef } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import "@adyen/adyen-web/dist/adyen.css"

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const Checkout = styled.div`
  flex: 1 0 auto;
`

const KlarnaCheckout = ({ snippet }) => {
  const kcoRef = useRef(null)
  const useMountEffect = (fun) => useEffect(fun, [])

  useMountEffect(() => {
    const scriptsTags = kcoRef.current.getElementsByTagName("script")
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement("script")
      newScriptTag.type = "text/javascript"
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }

    window._klarnaCheckout((api) => {
      api.on({
        load() {
          console.log("_klarnaCheckout:load")
          typeof window.gtag !== "undefined" &&
            window.gtag("event", "checkout_progress", {
              checkout_step: 4,
              checkout_option: "Started Payment"
            })
        },
        redirect_initiated() {
          console.log("_klarnaCheckout:redirect_initiated")
          window.gtag("event", "checkout_progress", {
            checkout_step: 5,
            checkout_option: "Payment Submited"
          })
        }
      })
    })
  })

  return <Checkout ref={kcoRef} dangerouslySetInnerHTML={{ __html: snippet }} />
}

export default ({ isShippingValid }) => {
  const { t } = useTranslation("translations")
  const { cartToKlarnaOrder, setIsLoading, cart } = useContext(BrinkContext)
  const [displayError, setDisplayError] = useState(false)
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)

  const isEmptyCart = (cart) => cart.cartItems.filter((p) => p.type === "productVariant").length === 0

  useEffect(() => {
    if (!isShippingValid || isEmptyCart(cart)) return
    setIsLoading(true)
    const createKlarnaCheckout = async () => {
      const klarnaOrder = await cartToKlarnaOrder({
        additional_checkbox: {
          text: t("Subscribe to newsletter"),
          checked: false,
          required: false
        }
      })
      setIsLoading(false)
      !klarnaSnippet && klarnaOrder && setKlarnaSnippet(klarnaOrder.html_snippet)
    }

    window._klarnaCheckout && window._klarnaCheckout((api) => api.suspend())

    createKlarnaCheckout()
      .catch(() => setDisplayError(true))
      .finally(() => {
        window._klarnaCheckout && window._klarnaCheckout((api) => api.resume())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShippingValid, cart])

  return (
    <>
      {displayError && (
        <ErrorMessage>
          {t("Something went wrong with your payment. Please try another option or contact our support.")}
        </ErrorMessage>
      )}
      {klarnaSnippet && <KlarnaCheckout snippet={klarnaSnippet} />}
    </>
  )
}
