import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE, MEDIA_MIN_X_LARGE } from "../../constants"
import Usp from "../widgets/Usp"
import Logo from "../../images/brink-logo.svg"
import Link from "../Link"
import CountdownBanner from "../widgets/CountdownBanner"

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  flex-wrap: wrap;

  svg {
    width: 10rem;
    padding: 3.5rem 0;
    fill: ${(p) => p.theme.colors.secondary};

    ${MEDIA_MIN_X_LARGE} {
      width: 11rem;
    }
  }
`

const TopBar = styled.div`
  width: 100%;
  margin: 0 auto;
  color: white;
  position: relative;
  height: 4.2rem;
  border-bottom: 1px solid ${(p) => p.theme.colors.primaryLight};
  padding-top: 0.2rem;
  font-size: 1.1rem;
  color: ${(p) => p.theme.colors.black};
`

const GoBack = styled(Link)`
  position: absolute;
  left: 3rem;
  top: 9.2rem;

  ${MEDIA_MIN_LARGE} {
    left: 0;
  }
`

const Help = styled(Link)`
  position: absolute;
  right: 3rem;
  top: 9.2rem;
  color: ${(p) => p.theme.colors.black};
  display: flex;
  align-items: center;

  i {
    font-size: 2rem;
  }

  span {
    display: none;
  }

  ${MEDIA_MIN_MEDIUM} {
    span {
      display: block;
      margin-right: 1rem;
    }
  }

  ${MEDIA_MIN_LARGE} {
    right: 0;
  }
`

export default () => {
  const { t } = useTranslation("translations")
  const { colors } = useContext(ThemeContext)

  return (
    <>
      <Container>
        <TopBar>
          <Usp inverted />
        </TopBar>
        <GoBack underline color={colors.black} to="/">
          <i className="fal fa-angle-left"></i> {t("Shop")}
        </GoBack>
        <Link to="/">
          <Logo alt="Brink Demo logo" />
        </Link>
        <Help to="/contact/" title={t("Need assistance?")}>
          <span>{t("Need assistance?")}</span>
          <i className="fal fa-user-headset"></i>
        </Help>
      </Container>
      <CountdownBanner />
    </>
  )
}
