import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Controller, ErrorMessage } from "react-hook-form"
import { useTranslation } from "react-i18next"
import countries from "i18n-iso-countries"
import CountryRegionData from "country-region-data/data"
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))

const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 3.4rem;
`

const SelectWrapper = styled.div`
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    border: 0.5rem solid transparent;
    border-top: 0.6rem solid gray;
    border-bottom: 0 none;
    position: absolute;
    right: 2rem;
    top: 2.5rem;
  }
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  border-top: 0.2rem solid ${(p) => p.theme.colors.errors};
  width: 100%;
  padding-top: 0.4rem;
  letter-spacing: 0;
  font-size: 1.3rem;
`

const Label = styled.p`
  margin: 3.6rem 0 0.8rem;
  text-transform: uppercase;
  font-size: 1.2rem;
`

const CountrySelector = ({
  countryWhiteList = [],
  currentStore,
  control,
  errors,
  languageCode,
  setCurrentStore,
  stores,
  name
}) => {
  const { t } = useTranslation("translations")

  const [countryList, setCountryList] = useState()

  useEffect(() => {
    const filteredCountries = CountryRegionData.filter((c) => countryWhiteList.indexOf(c.countryShortCode) > -1)
    setCountryList(
      <select>
        {filteredCountries.map((c) => (
          <option
            aria-label="Country"
            value={c.countryShortCode}
            key={c.countryShortCode}
            label={countries.getName(c.countryShortCode, languageCode)}
          />
        ))}
      </select>
    )
  }, [countryWhiteList, languageCode])

  if (!countryList) return null

  return (
    <SelectContainer>
      <Label>{t("Country")}</Label>
      <SelectWrapper>
        <Controller
          onChange={(e) => {
            const selectedStore = stores.find((s) => s.countryCode === e[0].target.value)
            setCurrentStore(selectedStore)
            return selectedStore.countryCode
          }}
          as={countryList}
          name={name}
          control={control}
          defaultValue={currentStore.countryCode}
        />
      </SelectWrapper>
      {errors && (
        <ErrorMessage errors={errors} name={name}>
          {({ message }) => <Validation>{message}</Validation>}
        </ErrorMessage>
      )}
    </SelectContainer>
  )
}

export default CountrySelector
