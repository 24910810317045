import React, { useContext, useEffect, useState, useRef } from "react"
import styled, { withTheme } from "styled-components"
import { isEqual } from "lodash"
import { BrinkContext } from "../context/BrinkContext"
import { Loading } from "../ui/Loader"

const Container = styled.div`
  flex: 1 0 100%;
  position: relative;
  min-height: 15em;
  padding: 1em;
`

const ShippingLoader = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ShippingIngrid = withTheme(({ theme, setIsShippingValid }) => {
  const {
    syncIngridDeliveryCheckout,
    cart,
    shippingAddress,
    updateShippingAddress,
    setIsLoading,
    removeShippingMethod
  } = useContext(BrinkContext)
  const [init, setInit] = useState(false)
  const widgetRef = useRef(null)
  const requireAddress = useRef(false)
  const useMountEffect = (fun) => useEffect(fun, [])
  const useEffectWhen = (effect, deps, whenDeps) => {
    const whenRef = useRef(whenDeps || [])
    const initial = whenRef.current === whenDeps
    const whenDepsChanged = initial || !whenRef.current.every((w, i) => isEqual(w, whenDeps[i]))
    whenRef.current = whenDeps
    const nullDeps = deps.map(() => null)

    return useEffect(whenDepsChanged ? effect : () => {}, whenDepsChanged ? deps : nullDeps)
  }

  useMountEffect(() => {
    const mount = async () => {
      const ingrid = await syncIngridDeliveryCheckout()

      if (ingrid) {
        widgetRef.current.innerHTML = ingrid.htmlSnippet
        const displayUpfrontAddressMatch = ingrid.htmlSnippet.match(/"display_upfront_address":(\w*)/)
        if (displayUpfrontAddressMatch?.length > 0) {
          const shouldRequireAddress = /true/i.test(displayUpfrontAddressMatch[1])
          requireAddress.current = shouldRequireAddress
        }
        replaceScriptNode(document.getElementById("shipwallet-container"))
        setupListeners()
      }
    }
    removeShippingMethod()
    mount()
  })

  useEffectWhen(
    () => {
      if (!init) return
      if (window._sw) {
        window._sw((api) => api.suspend())
      }
      syncIngridDeliveryCheckout().then(() => {
        setInit(true)
        if (shippingAddress.postalCode) {
          setIsShippingValid(true)
        }
        window._sw((api) => api.resume())
      })
    },
    [cart.cartItems, cart.discounts, init, setInit],
    [cart.cartItems, cart.discounts]
  )

  const onLoaded = () => {
    console.log("ingrid:loaded")
    setInit(true)
  }

  const onShippingOptionChanged = (option) => {
    console.log("ingrid:shipping_option_changed", option)
    setIsLoading(true)
    return setTimeout(() => {
      syncIngridDeliveryCheckout(false).then(() => {
        if (shippingAddress.postalCode || !requireAddress.current) {
          setIsShippingValid(true)
        }
      })
    }, 100)
  }

  const onAddressChanged = (address) => {
    console.log("ingrid:address_changed", address)
    updateShippingAddress({
      postalCode: address.postal_code,
      country: address.country_code
    })
    if (address.postal_code) {
      setIsShippingValid(true)
    }
  }

  const setupListeners = () => {
    window._sw((api) => {
      api.on("loaded", onLoaded)
      api.on("shipping_option_changed", onShippingOptionChanged)
      api.on("address_changed", onAddressChanged)
    })
  }

  return (
    <Container>
      {!init && <ShippingLoader color={theme.colors.primary} />}
      <div ref={widgetRef} />
    </Container>
  )
})

const isScriptNode = (node) => {
  return node.tagName === "SCRIPT"
}

const isExternalScript = (node) => {
  return !!node.src && node.src !== ""
}

const cloneScriptNode = (node) => {
  const script = document.createElement("script")
  script.text = node.innerHTML
  for (let i = node.attributes.length - 1; i >= 0; i--) {
    script.setAttribute(node.attributes[i].name, node.attributes[i].value)
  }
  return script
}

const replaceScriptNode = (node) => {
  if (isScriptNode(node) && !isExternalScript(node)) {
    if (node.parentNode) {
      node.parentNode.replaceChild(cloneScriptNode(node), node)
    }
  } else {
    let i = 0
    const children = node.childNodes
    while (i < children.length) {
      replaceScriptNode(children[i++])
    }
  }
  return node
}

export default ShippingIngrid
