import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Box = styled.div`
  position: relative;
  border-top: 0.1rem solid ${(p) => p.theme.colors.secondary};
  padding: 2rem 0 3rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    border: 0.1rem solid ${(p) => p.theme.colors.secondary};
    padding: 2rem 3rem 3rem;
    margin-bottom: 4rem;
  }

  h3 {
    font-family: "Josefin Sans", "sans-serif";
    position: absolute;
    background: ${(p) => p.theme.colors.white};
    top: -2.1rem;
    padding: 0 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.4rem;

    ${MEDIA_MIN_MEDIUM} {
      left: auto;
      transform: translate(0, 0);
    }
  }
`

export default ({ children, ...other }) => <Box {...other}>{children}</Box>
