import React, { useState, useContext, useEffect } from "react"
import jwt from "jsonwebtoken"
import { navigate } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router"
import { theme } from "../theme"
import { BrinkContext } from "../components/context/BrinkContext"
import { PageProvider } from "../components/context/PageContext"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "../constants"
import Head from "../components/Head"
import Header from "../components/checkout/Header"
import Loader from "../components/ui/Loader"
import Item from "../components/cart/Item"
import BottomBar from "../components/footer/BottomBar"
import Totals from "../components/cart/Totals"
import Box from "../components/checkout/Box"
import Discount from "../components/checkout/Discount"
import Shipping from "../components/checkout/Shipping"
import Payment from "../components/checkout/Payment"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Body = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 3rem 3rem 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0rem;
  }

  h1 {
    width: 100%;
    margin: 1rem 3rem 4rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 6rem 3rem 6rem;
    }
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

const GridItem = styled.div`
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: calc(50% - 2rem);
  }
`

const Cart = styled(Box)`
  padding-top: 1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 3rem 2rem;
  }
`

const Items = styled.div`
  width: 100%;
`

const CartTotals = styled(Totals)`
  > div {
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};

      ${MEDIA_MIN_MEDIUM} {
        border-bottom: none;
      }
    }
  }
`

const Footer = styled.div`
  margin-top: 4rem;

  ${MEDIA_MIN_MEDIUM} {
    margin-top: 6rem;
  }
`

const Failure = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  margin-bottom: 3rem;
  background: #f9c7c5;
  color: #9e362c;

  i {
    font-size: 3rem;
    margin-right: 1rem;
  }
`
const _getAvailablePaymentMethods = (currentStore, adyenSessionEnabled) => {
  const paymentMethods = []
  if (!["US", "CA", "AE"].includes(currentStore)) {
    paymentMethods.push("Klarna")
  }
  if (adyenSessionEnabled) {
    paymentMethods.push("AdyenSession")
  } else {
    paymentMethods.push("Adyen")
  }
  return paymentMethods
}

export default ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const useMountEffect = (fun) => useEffect(fun, [])
  const location = useLocation()
  const { cart, isLoading, getSetCart, ENABLE_INGRID_DELIVERY_CHECKOUT, ADYEN_SESSION_ENABLED, currentStore } =
    useContext(BrinkContext)
  const [isShippingValid, setIsShippingValid] = useState(false)
  const [shippingProvider, setShippingProvider] = useState(() => (ENABLE_INGRID_DELIVERY_CHECKOUT ? "Ingrid" : "Brink"))
  const [resultCode, setResultCode] = useState(null)
  const setCart = (token) => getSetCart(jwt.decode(token).sessionId, token)
  const [availablePaymentProviders, setAvailablePaymentProviders] = useState(
    _getAvailablePaymentMethods(currentStore.countryCode, ADYEN_SESSION_ENABLED)
  )

  const isEmptyCart = (cart) => cart.cartItems.filter((p) => p.type === "productVariant").length === 0

  useEffect(() => {
    if (isEmptyCart(cart)) {
      navigate("/")
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  useEffect(() => {
    setAvailablePaymentProviders(_getAvailablePaymentMethods(currentStore.countryCode, ADYEN_SESSION_ENABLED))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore])

  useMountEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("resultCode")) setResultCode(params.get("resultCode"))
    if (params.get("cart")) setCart(params.get("cart"))
    if (!params.get("cart") && (!cart || cart.cartItems.length === 0)) {
      navigate("/")
      return
    }
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "checkout_progress", {
        checkout_step: 1,
        checkout_option: "Started Checkout"
      })
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "begin_checkout", {
        items: cart.cartItems.map((i) => ({
          id: i.id,
          name: i.name,
          brand: "CaiaCosmetics",
          category: i.category,
          quantity: i.quantity
        }))
      })
    return
  })

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <PageProvider pageContext={pageContext}>
        {cart && cart.cartItems.length > 0 && (
          <Wrapper className="top">
            <Head meta={{ title: "Checkout" }} />
            <Header />
            <Body>
              <Loader isLoading={isLoading} />
              <h1>{t("Checkout")}</h1>
              {resultCode === "payment-failure" && (
                <Failure>
                  <i className="fal fa-exclamation-circle"></i>
                  {t("Something went wrong with your payment. Please try another option or contact our support.")}
                </Failure>
              )}
              {resultCode === "canceled" && (
                <Failure>
                  <i className="fal fa-exclamation-circle"></i>
                  {t("Your order has been canceled.")}
                </Failure>
              )}
              <Grid>
                <GridItem>
                  <Cart>
                    <h3>{t("Cart")}</h3>
                    <Items onClick={() => setIsShippingValid(shippingProvider !== "Ingrid")}>
                      {cart.cartItems.map((item) => (
                        <Item item={item} key={item.rowId} showControls={true} />
                      ))}
                    </Items>
                    <CartTotals checkoutPrice />
                  </Cart>
                  <Box>
                    <h3>{t("Discount")}</h3>
                    <Discount />
                  </Box>
                </GridItem>
                <GridItem>
                  <Shipping
                    shippingProvider={shippingProvider}
                    setShippingProvider={setShippingProvider}
                    sanityShippingOptions={pageContext.sanityShippingOptions}
                    setIsShippingValid={setIsShippingValid}
                  />
                  <Payment availablePaymentProviders={availablePaymentProviders} isShippingValid={isShippingValid} />
                </GridItem>
              </Grid>
            </Body>
            <Footer>
              <BottomBar />
            </Footer>
          </Wrapper>
        )}
      </PageProvider>
    </ThemeProvider>
  )
}
