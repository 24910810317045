import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Box from "./Box"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import PaymentKlarna from "./PaymentKlarna"
import PaymentAdyen from "./PaymentAdyen"
import PaymentAdyenSession from "./PaymentAdyenSession"
import { navigate } from "gatsby"

const PaymentMethods = styled(Box)`
  min-height: 35rem;
  padding: 0;
  margin-top: -0.1rem;

  h3 {
    margin: 3rem 2rem 1rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 3rem 3rem 0;
    }
  }

  > p {
    padding: 0 2rem;
    text-align: left;
    line-height: 1.8rem;
  }
`

const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
`

const Tab = styled.button`
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.white)};
  cursor: pointer;
  padding-bottom: 0.2rem;
  font-size: 1.3rem;
  line-height: 1.4rem;
  font-weight: 700;
  transition: all 0.2s;
  z-index: ${(p) => (p.active ? 1 : 0)};
  border: 0.1rem solid ${(p) => (p.active ? p.theme.colors.secondary : p.theme.colors.lightBorder)};
  border-top: 0.4rem solid ${(p) => (p.active ? p.theme.colors.pink : p.theme.colors.lightBorder)};
  border-bottom: 0.1rem solid ${(p) => (p.active ? "transparent" : p.theme.colors.lightBorder)};

  &:hover {
    border-top: 0.4rem solid ${(p) => (!p.active ? p.theme.colors.lightGrey : "none")};
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`

const Payment = ({ availablePaymentProviders, isShippingValid }) => {
  const [init, setInit] = useState(false)
  if (!availablePaymentProviders.length) {
    navigate("/error")
  }
  const [paymentProvider, setPaymentProvider] = useState(availablePaymentProviders[0])
  const { t } = useTranslation("translations")

  useEffect(() => {
    if (isShippingValid) {
      setInit(true)
    }
  }, [isShippingValid, setInit])

  if (!init) return null
  return (
    <>
      {availablePaymentProviders.length > 1 && (
        <Tabs>
          {availablePaymentProviders.map((p) => {
            return (
              <Tab active={p === paymentProvider} onClick={() => setPaymentProvider(p)} key={p}>
                {p === "Klarna" ? "Klarna" : t("Other options")}
              </Tab>
            )
          })}
        </Tabs>
      )}

      <PaymentMethods>
        {paymentProvider === "Klarna" && <PaymentKlarna isShippingValid={isShippingValid} />}
        {paymentProvider === "Adyen" && <PaymentAdyen isShippingValid={isShippingValid} />}
        {paymentProvider === "AdyenSession" && <PaymentAdyenSession isShippingValid={isShippingValid} />}
      </PaymentMethods>
    </>
  )
}

export default Payment
