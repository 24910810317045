import React, { useContext } from "react"
import styled, { withTheme } from "styled-components"
import Box from "./Box"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import ShippingIngrid from "./ShippingIngrid"
import ShippingBrink from "./ShippingBrink"
import { BrinkContext } from "../context/BrinkContext"
import { useState } from "react"

const ShippingProviders = styled(Box)`
  padding: 0;
  margin-top: -0.1rem;
  display: flex;
  justify-content: stretch;

  h3 {
    margin: 3rem 2rem 1rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 3rem 3rem 0;
    }
  }

  > p {
    padding: 0 2rem;
    text-align: left;
    line-height: 1.8rem;
  }
`

const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
`

const Tab = styled.button`
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.white)};
  cursor: pointer;
  padding-bottom: 0.2rem;
  font-size: 1.3rem;
  line-height: 1.4rem;
  font-weight: 700;
  transition: all 0.2s;
  z-index: ${(p) => (p.active ? 1 : 0)};
  border: 0.1rem solid ${(p) => (p.active ? p.theme.colors.secondary : p.theme.colors.lightBorder)};
  border-top: 0.4rem solid ${(p) => (p.active ? p.theme.colors.pink : p.theme.colors.lightBorder)};
  border-bottom: 0.1rem solid ${(p) => (p.active ? "transparent" : p.theme.colors.lightBorder)};

  &:hover {
    border-top: 0.4rem solid ${(p) => (!p.active ? p.theme.colors.lightGrey : "none")};
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`

const Shipping = withTheme(({ sanityShippingOptions, theme, setIsShippingValid }) => {
  const onClickHandler = (provider) => () => {
    setIsShippingValid(false)
    setShippingProvider(provider)
  }

  const { ENABLE_INGRID_DELIVERY_CHECKOUT, ENABLE_BRINK_SHIPPING } = useContext(BrinkContext)

  const [shippingProvider, setShippingProvider] = useState(ENABLE_BRINK_SHIPPING ? "Brink" : "Ingrid")
  return (
    <>
      {ENABLE_INGRID_DELIVERY_CHECKOUT && ENABLE_BRINK_SHIPPING && (
        <Tabs>
          <Tab active={shippingProvider === "Ingrid"} onClick={onClickHandler("Ingrid")}>
            Ingrid shipping
          </Tab>
          <Tab active={shippingProvider === "Brink"} onClick={onClickHandler("Brink")}>
            Brink shipping
          </Tab>
        </Tabs>
      )}
      <ShippingProviders>
        {shippingProvider === "Ingrid" && ENABLE_INGRID_DELIVERY_CHECKOUT && (
          <ShippingIngrid setIsShippingValid={setIsShippingValid} />
        )}
        {shippingProvider === "Brink" && ENABLE_BRINK_SHIPPING && (
          <ShippingBrink
            theme={theme}
            sanityShippingOptions={sanityShippingOptions}
            setIsShippingValid={setIsShippingValid}
          />
        )}
      </ShippingProviders>
    </>
  )
})

export default Shipping
