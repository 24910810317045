import React, { useContext, useEffect, useState } from "react"
import styled, { withTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import Dinero from "dinero.js"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import Box from "./Box"
import Radio from "../ui/Radio"
import { Loading } from "../ui/Loader"

const Container = styled(Box)`
  padding: 1rem 0 0;
  position: relative;
  min-height: 6em;
  border: none;
  margin-bottom: 0;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0.5rem 0 0;
    border: none;

    h3 {
      margin-left: 3rem;
    }
  }
`

const ShippingOption = styled.div`
  padding: 1.2rem 0;
  width: 100%;
  position: relative;

  & + & {
    border-top: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  }

  input {
    margin: 0;
  }

  > div {
    height: 6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2.4rem 3rem;
  }
`

const ShippingOptionLabel = styled.label`
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  padding: 0.2rem 0 0 3.2rem;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0.2rem 7rem 0 6.2rem;
  }

  span {
    display: flex;
    padding-right: 7rem;
    line-height: 1.6rem;
  }

  img {
    height: 3rem;
    margin-right: 1rem;
  }
`

const Description = styled.p`
  width: 100%;
  text-transform: initial;
  margin: 0.4rem 0 0;
  opacity: 0.5;
  line-height: 1.6rem;
`

const Price = styled.div`
  position: absolute;
  right: 0;
  top: 2.5rem;

  ${MEDIA_MIN_MEDIUM} {
    top: 3.8rem;
    right: 3rem;
  }
`

const ShippingLoader = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const toDinero = (amount, currencyUnit) => {
  return Dinero({ amount: amount, currency: currencyUnit })
}

const ShippingBrink = withTheme(({ sanityShippingOptions, theme, setIsShippingValid }) => {
  const { t } = useTranslation("translations")
  const { shippingMethod, setShippingMethod, getShippingOptions, languageCode, currentStore, cart } =
    useContext(BrinkContext)

  const [shippingOptions, setShippingOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [cartPrice, setCartPrice] = useState(() => cart.totalPrice)
  const { currencyUnit } = currentStore

  useEffect(
    () => {
      getShippingOptions(currentStore.countryCode).then((options) => {
        const shippingOptions = sanityShippingOptions
          .map((shippingOption) => toShippingOption(options, shippingOption))
          .filter((x) => x)
          .sort((a, b) => a.sortOrder - b.sortOrder)
        setShippingOptions(shippingOptions)
        if (!shippingMethod) setShippingMethod(shippingOptions[0])
        setIsShippingValid(true)
        setIsLoading(false)
      })
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cart.cartItems, cart.discounts])

  useEffect(() => {
    if (cart.state === "CLOSED") return
    cartPrice !== cart.totalPrice && setCartPrice(cart.totalPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const toShippingOption = (shippingOptions, shippingOption) => {
    const availableShippingOption = shippingOptions.find((o) => o.id === shippingOption.id)
    return availableShippingOption
      ? {
          ...availableShippingOption,
          sortOrder: shippingOption.sortOrder
        }
      : undefined
  }

  const handleShippingClick = (id) => {
    setShippingMethod(shippingOptions.find((o) => o.id === id))
  }

  const isPreSelected = (shippingOption) =>
    (shippingMethod && shippingOption.id === shippingMethod.id) || shippingOption.sortOrder === "1"

  return (
    <Container>
      {isLoading && <ShippingLoader color={theme.colors.primary} />}
      {shippingOptions.map((shippingOption) => {
        const { displayName, description } = shippingOption.attribute
        const price =
          !shippingOption.price[currencyUnit] ||
          (shippingOption?.discount && shippingOption.price[currencyUnit] === shippingOption.discount[currencyUnit])
            ? t("Free")
            : toDinero(shippingOption.price[currencyUnit], currencyUnit).toFormat()

        return (
          <ShippingOption
            key={shippingOption.id}
            onClick={() => shippingMethod?.id !== shippingOption.id && handleShippingClick(shippingOption.id)}
            htmlFor={shippingOption.id}
          >
            <Radio
              id={shippingOption.id}
              name="shipping"
              preSelected={isPreSelected(shippingOption) ? "true" : undefined}
            />
            <ShippingOptionLabel htmlFor={shippingOption.id}>
              <div>
                <span>{displayName[languageCode] || displayName.en}</span>
                <Description>{description[languageCode] || description.en}</Description>
              </div>
              <Price>{price}</Price>
            </ShippingOptionLabel>
          </ShippingOption>
        )
      })}
    </Container>
  )
})

export default ShippingBrink
