import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"
import { BrinkContext } from "../context/BrinkContext"
import Input from "../ui/Input"
import RegionSelector from "./RegionSelector"
import CountrySelector from "./CountrySelector"

const Container = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
`

const Field = styled(Input)`
  input {
    border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
    width: 100%;
    text-transform: initial;
  }
`

const Firstname = styled(Field)`
  width: 50%;
`

const Lastname = styled(Field)`
  width: calc(50% - 1rem);
  margin-left: 1rem;
`

const PostalCode = styled(Field)`
  width: 40%;
`

const City = styled(Field)`
  width: calc(60% - 1rem);
  margin-left: 1rem;
`

export default ({ control, errors, setValue }) => {
  const { t } = useTranslation("translations")
  const { shippingAddress, countryWhiteList, currentStore, setCurrentStore, stores, languageCode } =
    useContext(BrinkContext)

  const setEmailFocused = (value) => {
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "checkout_progress", {
        checkout_step: 2,
        checkout_option: "Started Address Form"
      })
    _setEmailFocused(value)
  }
  const [emailFocused, _setEmailFocused] = useState(false)

  useEffect(() => {
    setValue([
      { email: shippingAddress.email },
      { firstName: shippingAddress.firstName },
      { lastName: shippingAddress.lastName },
      { phone: shippingAddress.phone },
      { address: shippingAddress.address },
      { houseNumberOrName: shippingAddress.houseNumberOrName },
      { postalCode: shippingAddress.postalCode },
      { city: shippingAddress.city },
      { country: shippingAddress.country }
    ])
  }, [setValue, shippingAddress])

  return (
    <Container>
      <Controller
        as={
          <Field
            onFocus={() => {
              !emailFocused && setEmailFocused(true)
            }}
          />
        }
        control={control}
        rules={{
          required: t("This is a required field"),
          pattern: {
            value:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
            message: t("Please supply a valid e-mail address")
          }
        }}
        type="email"
        name="email"
        label={t("E-mail")}
        contextValue={shippingAddress.email}
        errors={errors}
      />

      <Controller
        as={Firstname}
        control={control}
        rules={{
          required: t("This is a required field")
        }}
        type="text"
        name="firstName"
        label={t("First name")}
        contextValue={shippingAddress.firstName}
        errors={errors}
      />

      <Controller
        as={Lastname}
        control={control}
        rules={{
          required: t("This is a required field")
        }}
        type="text"
        name="lastName"
        label={t("Last name")}
        contextValue={shippingAddress.lastName}
        errors={errors}
      />

      <Controller
        as={Field}
        control={control}
        rules={{
          required: t("This is a required field")
        }}
        type="tel"
        name="phone"
        label={t("Telephone")}
        contextValue={shippingAddress.phone}
        errors={errors}
      />

      <Controller
        as={Field}
        control={control}
        rules={{
          required: t("This is a required field")
        }}
        type="text"
        name="address"
        label={t("Address")}
        contextValue={shippingAddress.address}
        errors={errors}
      />

      <Controller
        as={Field}
        control={control}
        type="text"
        name="houseNumberOrName"
        label={t("House number or name")}
        contextValue={shippingAddress.houseNumberOrName}
        errors={errors}
      />

      {currentStore.countryCode !== "AE" && (
        <Controller
          as={PostalCode}
          control={control}
          rules={{ required: t("This is a required field") }}
          type="text"
          name="postalCode"
          label={t("Postal code")}
          contextValue={shippingAddress.postalCode}
          errors={errors}
        />
      )}

      <Controller
        as={City}
        control={control}
        rules={{
          required: t("This is a required field")
        }}
        type="text"
        name="city"
        label={t("City")}
        contextValue={shippingAddress.city}
        errors={errors}
      />

      <RegionSelector countryCode={currentStore.countryCode} control={control} name="region" errors={errors} />

      <CountrySelector
        setCurrentStore={setCurrentStore}
        countryWhiteList={countryWhiteList}
        control={control}
        stores={stores}
        currentStore={currentStore}
        languageCode={languageCode}
        name="country"
        errors={errors}
      />
    </Container>
  )
}
